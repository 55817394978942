import * as React from "react";
import { CardContentSize, CollectionLodgingCarouselProps, LodgingCarouselViews } from "./typings";
import {
  CollectionItemClickthrough,
  CollectionLodgingCarousel as RetailCollectionLodgingCarousel,
} from "@shared-ui/retail-incentives-collections";
import { withStores } from "src/stores";
import { observer } from "mobx-react";
import { UitkSpacing } from "@egds/react-core/spacing";

export const CollectionLodgingCarousel = withStores("context")(
  observer((props: CollectionLodgingCarouselProps) => {
    const {
      context: { searchContext },
      templateComponent,
    } = props;
    const {
      carouselView = LodgingCarouselViews.Default,
      cardContentSize = CardContentSize.FOUNDATION,
      collectionID,
      collectionItemClickthrough = CollectionItemClickthrough.PINNED_SRP,
      collectionItemsPageSize,
      collectionItemsMaxPageSize,
      hideCollectionHeaderTitle,
      hideCollectionHeaderDescription,
      hideCollectionSummary,
      hideCollectionHeaderSubtitle,
      hideCollectionTermsAndConditions,
      hideCollectionCarouselCTA,
      scrimHue,
      scrimLightness,
      scrimSaturation,
    } = templateComponent.config;

    /**
     * Generate placement input data for CollectionTiles
     * @see https://github.expedia.biz/eg-incentives/incentives-docs/blob/master/docs/dive/placements.md
     */
    const inputData = {
      collectionId: collectionID,
      collectionItemClickthrough,
      pageContext: {
        propertySearch: { primary: { destination: { regionId: searchContext?.location?.id?.toString() }, rooms: [] } },
      },
      paginationContext: {
        pageSize: collectionItemsPageSize,
        maxPageSize: collectionItemsMaxPageSize,
      },
    };

    /* istanbul ignore next */
    const hideAttributes = {
      hideHeaderTitle: hideCollectionHeaderTitle ?? false,
      hideHeaderSubtitle: hideCollectionHeaderSubtitle ?? false,
      hideHeaderDescription: hideCollectionHeaderDescription ?? false,
      hideSummary: hideCollectionSummary ?? false,
      hideTermsAndConditions: hideCollectionTermsAndConditions ?? false,
      hideCarouselCTA: hideCollectionCarouselCTA ?? true,
    };

    return (
      <UitkSpacing margin={{ blockstart: "six" }}>
        <div>
          <RetailCollectionLodgingCarousel
            inputs={inputData}
            {...hideAttributes}
            cardContentSize={cardContentSize}
            view={carouselView}
            scrimHue={scrimHue}
            scrimLightness={scrimLightness}
            scrimSaturation={scrimSaturation}
          />
        </div>
      </UitkSpacing>
    );
  })
);

export default CollectionLodgingCarousel;
